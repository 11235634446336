import React, { Component } from "react";
import SideBarAdmin from "./side-bar-admin";
import AdminDataService from "../services/admin.service";
import UserService from "../services/user.service";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default class AdminDetailAsync extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      selectedSim: 0,
      detailList: [],
      isLoading: false,
      processPeriodZero: false,
      sim_id: localStorage.getItem("simID"),
      sim_group: localStorage.getItem("simGroup"),
      num_total: localStorage.getItem("total"),
      columns: [
        {
          dataField: "TeamID",
          text: "TeamID",
          sort: true,
          filter: textFilter(),
        },
        { dataField: "email", text: "Email", filter: textFilter() },
        {
          dataField: "periodCompleted",
          text: "Completed Period",
          sort: true,
        },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    UserService.getAdminBoard().then(
      (response) => {
        this.setState({
          isAdmin: true,
          isLoading: false,
        });
        AdminDataService.getDetailAsync({ sim_id: this.state.sim_id }).then(
          (response) => {
            let len = response.data[0]["notStartNum"];
            this.setState({
              detailList: response.data,
              num_notStart: len,
              num_started: this.state.num_total - len,
            });
          }
        );
      },
      (error) => {
        console.log(error.response);
      }
    );
  }

  render() {
    return (
      <div className="inlineFlex">
        <SideBarAdmin></SideBarAdmin>
        <div className="p20 w-100 inlineFlex">
          {this.state.isLoading ? (
            <div className="loader"></div>
          ) : (
            <span></span>
          )}
          {this.state.isAdmin ? (
            <div>
              <h3>
                Admin - {this.state.sim_id} - {this.state.sim_group}
              </h3>
              <h5>
                Started: {this.state.num_started} | Not Started:{" "}
                {this.state.num_notStart} | Total: {this.state.num_total}
              </h5>
              <span style={{ float: "right" }}>
                <ReactHTMLTableToExcel
                  id="table-xls-button"
                  className="download-table-xls-button"
                  table="detailList"
                  filename={"IncompleteEmails-" + this.state.sim_group}
                  sheet="Emails"
                  buttonText="Export to XLS"
                />
              </span>
              <h5> Detail List </h5>
              <div className="overflowAutoScroll">
                <BootstrapTable
                  id="detailList"
                  striped
                  bordered
                  hover
                  keyField="ID"
                  data={this.state.detailList}
                  columns={this.state.columns}
                  filter={filterFactory()}
                ></BootstrapTable>
              </div>
            </div>
          ) : (
            <h3> You need to be an admin to view this page. </h3>
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import ResourceDataService from "../services/resource.service";
import SideBar from "./side-bar.js";
import Chevron from "./chevron.js";
import ActionService from "../services/action.service";
import "./accordion.css";
import { Modal } from "react-bootstrap";


export default class AssignActions extends Component {
  constructor(props) {
    super(props);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.toggleExpandCollapse = this.toggleExpandCollapse.bind(this);
    this.toggleExpandCollapseAll = this.toggleExpandCollapseAll.bind(this);
    this.submitFun = this.submitFun.bind(this); 
    this.closeModal = this.closeModal.bind(this); 
    this.saveActions = this.saveActions.bind(this);
    this.fetchSavedActions = this.fetchSavedActions.bind(this);


    this.state = {
      isLoading: true,
      isExpanded: false,
      teamMembers: [],
      teamID: "",
      currentPeriod: "",
      periods: [],
      totalCosts: {},
      expandables: [],
      isModalOpen: false, 
      modalMessage: "",
    };
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  componentDidMount() {
    this.retrieveTeamID();
    this.setCurrentPeriod();
    debugger;
  
    if (localStorage.getItem("decisionsSubmitted") === "1") {
      setTimeout(() => {
        this.fetchData()
          .then(() => {
            this.fetchSavedActions();
            this.setState({
              disabledFlag: true,
            });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, 0);
    } else {
      // Call the fetchSavedActions method to get saved data and pre-select checkboxes
      setTimeout(() => {
        this.fetchData() // Call fetchData first
          .then(() => {
            this.fetchSavedActions();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, 0);
    }
  }
  
  
  
  

fetchSavedActions() {
  debugger
  ActionService.GetSelectedActionResource({
    period: parseInt(localStorage.getItem("currentPeriod")),
    projectId: parseInt(localStorage.getItem("simulation")),
  })
  .then((response) => {
    const savedActions = response.data;
    console.log("Received data from API:", response.data);
    debugger
    // Iterate through the saved actions and trigger toggleCheckbox for each combination
    savedActions.forEach((savedAction) => {
      this.toggleCheckbox(savedAction.actionID, savedAction.ResourceID);
    });
  })
  .catch((error) => {
    console.error("Error fetching saved action assignments:", error);
  });
}





  retrieveTeamID() {
    const teamID = localStorage.getItem("teamID");
    this.setState({ teamID });
  }

  setCurrentPeriod() {
    const currentPeriod = localStorage.getItem("currentPeriod");
    if (currentPeriod) {
      this.setState({ currentPeriod });
      
    }
  }

  handlePeriodChange = (event) => {
    const selectedPeriod = parseInt(event.target.value);
    this.setState({ currentPeriod: selectedPeriod });
    this.fetchResources(selectedPeriod);
  };

  
 fetchData() {
  // Fetch both actions and hired resources concurrently using Promise.all()
  return Promise.all([
    ActionService.retrieveActions(),
    ResourceDataService.retrieveResources({
      status: "On Team",
      projectId: localStorage.getItem("simulation"),
    }),
  ])
    .then(([actionResponse, hiredResourcesResponse]) => {
      const actionDetails = actionResponse.data;
      const hiredResources = hiredResourcesResponse.data;

      const expandables = actionDetails.map((action) => {
        const hiredResourcesForAction = hiredResources.map((resource) => ({
          resourceID: resource.resourceID,
          resourceName: resource.resourceName,
          resourceCategory: resource.resourceCategory,
          resourceRate: resource.resourceRate,
          RegionName: resource.RegionName,
          resourceSatisfaction: resource.Resource_Satisfaction
        }));

        return {
          actionId: action.ID,
          title: action.actionName,
          hiredResources: hiredResourcesForAction,
          selectedResources: [],
          isExpanded: false,
          actionCost: action.actionCost, 
        };
      });

      this.setState({ expandables, isLoading: false });
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      // Handle the error as needed
      // You might want to reject the promise here or perform error handling.
    });
}

  
  submitFun() {
    // Set the modal state to open and confirmation status to false
    this.setState({
      isModalOpen: true,
      confirmationStatus: false,
      modalMessage:
        "Are you sure you want to submit the action selection for this period?",
    });
  }

  saveFunction = () => {
    // Close the modal
    this.setState({ isModalOpen: false });
  
    // Call the respective function, in this case, saveActions
    this.saveActions();
  };

  saveActions() {
    const { expandables } = this.state;
    const actions = [];
    if (expandables.every((action) => action.selectedResources.length === 0)) {
      actions.push({
        periodNum: parseInt(localStorage.getItem("currentPeriod")),
        projectID: parseInt(localStorage.getItem("simulation")),
      });
    }
    else {
    expandables.forEach((action) => {
      const { selectedResources, actionId, periodNum, projectId } = action;
      //console.log(action)
      selectedResources.forEach((resourceId) => {
        actions.push({
          resourceID: parseInt(resourceId),
          actionID: parseInt(actionId),
          periodNum: parseInt(localStorage.getItem("currentPeriod")),
          projectID: parseInt(localStorage.getItem("simulation")),
        });
      });
    });
    }
    console.log('action printed: ', actions)

    // Now "actions" array contains the selected resources with their associated action details
    // Call the backend API to save the data using "actions"
    ActionService.saveActionAssignment({
      actions: actions,
    })
      .then((response) => {
        console.log("Data saved successfully:", response);
        const costInfoArray = this.logCostInformation();
        console.log("Cost information:", costInfoArray);
      
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        
      });

     
  }


toggleAccordion(actionId) {
  this.setState((prevState) => ({
    expandables: prevState.expandables.map((action) =>
      action.actionId === actionId ? { ...action, isExpanded: !action.isExpanded } : action
    ),
  }));
}


toggleCheckbox(actionId, resourceId) {
  debugger
  this.setState((prevState) => ({
    expandables: prevState.expandables.map((action) => {
      if (action.actionId === actionId) {
        const selectedResources = action.selectedResources.includes(resourceId)
          ? action.selectedResources.filter((id) => id !== resourceId)
          : [...action.selectedResources, resourceId];
        return { ...action, selectedResources };
      } else {
        return action;
      }
    }),
  }), () => {
    this.calculateTotalCost();
  });
}


logCostInformation() {
  const { expandables, totalCosts } = this.state;

  // Create an array to hold the cost information for each action
  const costInfoArray = [];

  // Calculate and log cost for each action
  expandables.forEach((action) => {
    const costObj = {
      [action.title]: totalCosts[action.title] || 0,
    };
    costInfoArray.push(costObj);
  });

  // Calculate and log total cost of all actions
  const totalCost = Object.values(totalCosts).reduce((total, cost) => total + cost, 0);
  const totalCostObj = {
    TotalCostofAll: totalCost,
  };
  costInfoArray.push(totalCostObj);

  // Return the array with cost information
  return costInfoArray;
}


toggleSelectAll(actionId) {
  this.setState(
    (prevState) => {
      const action = prevState.expandables.find((action) => action.actionId === actionId);
      const allResourceIds = action.hiredResources.map((resource) => resource.resourceID);
      const selectedResources = action.selectedResources.length === allResourceIds.length ? [] : allResourceIds;
      const updatedExpandables = prevState.expandables.map((action) => {
        if (action.actionId === actionId) {
          return { ...action, selectedResources };
        } else {
          return action;
        }
      });
      return { expandables: updatedExpandables };
    },
    () => {
      this.calculateTotalCost();
    }
  );
}


toggleExpandCollapse() {
  this.setState((prevState) => ({
    isExpanded: !prevState.isExpanded,
    expandables: prevState.expandables.map((action) => ({
      ...action,
      isExpanded: !prevState.isExpanded,
    })),
  }));
}

toggleExpandCollapseAll() {
  this.setState((prevState) => ({
    isExpanded: !prevState.isExpanded,
    expandables: prevState.expandables.map((action) => ({
      ...action,
      isExpanded: !prevState.isExpanded,
    })),
  }));
}


calculateTotalCost() {
  const { expandables } = this.state;
  const totalCosts = {};

  expandables.forEach((action) => {
    const { selectedResources, hiredResources, actionCost } = action;

    const selectedHiredResources = hiredResources.filter((resource) =>
      selectedResources.includes(resource.resourceID)
    );

    const actionTotalCost = selectedHiredResources.reduce((total, resource) => {
      return total + actionCost; // Use actionCost instead of resource.resourceRate
    }, 0);

    totalCosts[action.title] = actionTotalCost; // Use action.title as the key
  });

  this.setState({ totalCosts });
}


render() {
  const { teamMembers, teamID, currentPeriod } = this.state;
  const { isLoading, expandables, isExpanded, totalCosts } = this.state;
  //console.log("Expandables:", this.state.expandables);
  const expandCollapseButtonText = isExpanded ? "Collapse All" : "Expand All";
  //console.log(expandables)
  const actionDisplay = expandables.map((action) => (
    <div key={action.actionId}>
      <div
        className={`accordion__section ${action.isExpanded ? "expanded" : ""}`}
        style={{
          border: "1px solid",
          transition: "height 0.3s ease-in-out",
          overflow: "hidden",
        }}
      >
        <button
          className={`accordion ${action.isExpanded ? "active" : ""}`}
          onClick={() => this.toggleAccordion(action.actionId)}
        >
          <span className="accordion__title">{action.title}</span>
          <Chevron
            className={`accordion__icon ${action.isExpanded ? "rotate" : ""}`}
            width={10}
            fill={"#777"}
          />
        </button>
        {action.isExpanded && (
          // // <div className="accordion__content" disabled={this.state.disabledFlag}>
          <div className={`accordion__content ${this.state.disabledFlag ? "disabled" : ""}`}>
            <div className="accordion__text" >
              {action.hiredResources.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped" >
                      <thead >
                        <tr>
                          <th>Name</th>
                          <th>Role</th>
                          <th>Rate</th>
                          <th>Region</th>
                          <th>Satisfaction</th>
                          <th>Cost</th>
                          {/* New column */}
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {action.hiredResources.map((resource) => (
                          <tr key={resource.resourceID}>
                            <td>{resource.resourceName}</td>
                            <td>{resource.resourceCategory}</td>
                            <td>{resource.resourceRate}</td>
                            <td>{resource.RegionName}</td>
                            <td>{resource.resourceSatisfaction}</td>
                            <td>{action.actionCost}</td>
                            {/* Display the cost value */}
                            <td>
                              <input
                                disabled={this.state.disabledFlag}
                                type="checkbox"
                                checked= {action.selectedResources.includes(resource.resourceID)}
                                onChange={() => this.toggleCheckbox(action.actionId, resource.resourceID)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="select-all-container">
                    <label>
                      <input
                        disabled={this.state.disabledFlag}
                        type="checkbox"
                        checked={action.selectedResources.length === action.hiredResources.length}
                        onChange={() => this.toggleSelectAll(action.actionId)}
                      />
                      Select All
                    </label>
                  </div>
                </>
              ) : (
                <div>No hired resources available for this action.</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  ));

  // Calculate the total cost of all actions combined
  const totalCost = Object.values(totalCosts).reduce((total, cost) => total + cost, 0);

  return (
    <div className="inlineFlex">
      <SideBar />
      <div className="p20" style={{ width: "100%" }}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h5>Leadership Actions</h5>
              <div className="team-id">
                <section><strong>Team ID: </strong>{teamID}</section>
              </div>
              <div className="team-current-period">
                <section><strong>Current Period: </strong>{currentPeriod}</section>
              </div>
              <p>Listed below are six actions that can be assigned to the team. One or more team members can be assigned for each action.</p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <button onClick={this.toggleExpandCollapseAll}>{expandCollapseButtonText}</button>
            </div>
          </div>
          <div className="action-cost-block">
  <table className="action-cost-table">
    <tbody>
      <tr>
        {expandables.map((action) => (
          <td key={action.actionId}>
            <div className="action-info">
              <div className="action-name" style={{ fontWeight: "bold" }}>
                {action.title}
              </div>
              {/* Add a line here */}
              <hr style={{ margin: "2px 0", border: "1px solid #ccc" }} />
              <div className="total-cost">${totalCosts[action.title] || '0'}</div> {/* Use action.title */}
            </div>
          </td>
        ))}
        <td>
          <div className="action-info">
            <div className="action-name" style={{ fontWeight: "bold" }}>
              Total Cost of All Actions
            </div>
            {/* Add a line here */}
            <hr style={{ margin: "2px 0", border: "1px solid #ccc" }} />
            <div className="total-cost total-cost-all">${totalCost || '0'}</div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div>
            <div style={{ marginTop: "15px" }}>
              {actionDisplay}
              <div style={{ marginTop: "15px" }}>
                Save action assignment by clicking the 'Save' button.
                <button style={{ float: "right", padding: "5px 25px" }} onClick={this.submitFun}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={this.state.isModalOpen} onHide={this.closeModal}>
  <Modal.Header closeButton>
    <Modal.Title>Confirm action assignment</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Save action assignment?</p>
  </Modal.Body>

  <Modal.Footer>
    <button variant="secondary" onClick={this.closeModal}>
      No
    </button>
    <button variant="primary" onClick={this.saveFunction}>
      Yes
    </button>
  </Modal.Footer>
</Modal>

    </div>
  );

}


}



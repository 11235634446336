import React, { Component } from "react";
import UserService from "../services/user.service";
import SideBarAdmin from "./side-bar-admin";
import BootstrapTable from "react-bootstrap-table-next";

export default class RankingAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: localStorage.getItem("currentPeriod"),
      periodList: [],
      showRankTabs: false,
      currentTab: 0,
      // teamID: 0,
      data: [
        {
          id: "1",
          name: "Overall",
          desc: "This tab displays the overall ranking.",
          active: true,
        },
        {
          id: "2",
          name: "Work",
          desc: "This tab displays the simulation ranking based on work.",
          active: false,
        },
        {
          id: "3",
          name: "Cost",
          desc: "This tab displays the simulation ranking based on cost.",
          active: false,
        },
        {
          id: "4",
          name: "Duration",
          desc: "This tab displays the simulation ranking based on duration.",
          active: false,
        },
      ],
      ranking: [],
      userRank: {},
      overallRankingColumns: [
        {
          dataField: "PriorPeriod_OverallRanking_Delta",
          text: "Change",
          formatter: this.overallRankingDeltaChangeVal,
        },
        {
          dataField: "TeamID",
          text: "Team",
          sort: true,
          formatter: this.projectIDChangeVal,
        },
        {
          dataField: "email",
          text: "Email",
        },
        { dataField: "OverallRanking", text: "Rank", sort: true },
        {
          dataField: "PriorPeriod_OverallRanking",
          text: "Prior Period Rank",
          sort: true,
        },
      ],
      workRankingColumns: [
        {
          dataField: "PriorPeriod_OverallRanking_Delta",
          text: "Change",
          formatter: this.workRankingDeltaChangeVal,
        },
        {
          dataField: "TeamID",
          text: "Team",
          sort: true,
          formatter: this.projectIDChangeVal,
        },
        {
          dataField: "email",
          text: "Email",
        },
        {
          dataField: "Work_Completed_in_Period",
          text: "Work Completed in Current Period",
        },
        {
          dataField: "TotalWorkCompleted",
          text: "Total Work Completed",
        },
        { dataField: "WorkRanking", text: "Rank", sort: true },
        {
          dataField: "PriorPeriod_WorkRanking",
          text: "Prior Period Rank",
          sort: true,
        },
      ],
      costRankingColumns: [
        {
          dataField: "PriorPeriod_CostRanking_Delta",
          text: "Change",
          formatter: this.costRankingDeltaChangeVal,
        },
        {
          dataField: "TeamID",
          text: "Team",
          sort: true,
          formatter: this.projectIDChangeVal,
        },
        {
          dataField: "email",
          text: "Email",
        },
        {
          dataField: "PeriodCost",
          text: "Period Cost",
        },
        {
          dataField: "TotalCost",
          text: "Total Cost",
        },
        { dataField: "CostRanking", text: "Rank", sort: true },
        {
          dataField: "PriorPeriod_CostRanking",
          text: "Prior Period Rank",
          sort: true,
        },
      ],
      durationRankingColumns: [
        {
          dataField: "PriorPeriod_DurationRanking_Delta",
          text: "Change",
          formatter: this.durationRankingDeltaChangeVal,
        },
        {
          dataField: "TeamID",
          text: "Team",
          sort: true,
          formatter: this.projectIDChangeVal,
        },
        {
          dataField: "email",
          text: "Email",
        },
        {
          dataField: "Estimated_Project_Duration",
          text: "Estimated Project Duration",
        },
        {
          dataField: "Estimated_Project_Finish",
          text: "Estimated Project Finish Date",
          formatter: this.estimatedProjectFinishChangeVal,
        },
        { dataField: "DurationRanking", text: "Rank", sort: true },
        {
          dataField: "PriorPeriod_DurationRanking",
          text: "Prior Period Rank",
          sort: true,
        },
      ],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.fetchRankings = this.fetchRankings.bind(this);
  }
  componentDidMount() {
    var periodVar = localStorage.getItem("currentPeriod") - 1;
    if (localStorage.getItem("simStatus") === "Completed") {
      periodVar = localStorage.getItem("currentPeriod");
    }

    var tempPeriodList = [];
    for (var i = 1; i <= periodVar; i++) {
      tempPeriodList.push(i);
    }
    this.setState({ periodList: tempPeriodList, period: periodVar }, () => {
      this.fetchRankings();
    });
  }

  projectIDChangeVal = (cell, row, rowIndex, formatExtraData) => {
    return <span>Team {row.TeamID}</span>;
  };

  overallRankingDeltaChangeVal = (cell, row, rowIndex, formatExtraData) => {
    if (row.PriorPeriod_OverallRanking_Delta > 0) {
      return (
        <span className="colGreen">
          &uarr; {row.PriorPeriod_OverallRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_OverallRanking_Delta < 0) {
      return (
        <span className="colRed">
          &darr; {row.PriorPeriod_OverallRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_OverallRanking_Delta === null) {
      return <span>-</span>;
    } else {
      return <span>{row.PriorPeriod_OverallRanking_Delta}</span>;
    }
  };
  workRankingDeltaChangeVal = (cell, row, rowIndex, formatExtraData) => {
    if (row.PriorPeriod_WorkRanking_Delta > 0) {
      return (
        <span className="colGreen">
          &uarr; {row.PriorPeriod_WorkRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_WorkRanking_Delta < 0) {
      return (
        <span className="colRed">
          &darr; {row.PriorPeriod_WorkRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_WorkRanking_Delta === null) {
      return <span>-</span>;
    } else {
      return <span>{row.PriorPeriod_WorkRanking_Delta}</span>;
    }
  };
  costRankingDeltaChangeVal = (cell, row, rowIndex, formatExtraData) => {
    if (row.PriorPeriod_CostRanking_Delta > 0) {
      return (
        <span className="colGreen">
          &uarr; {row.PriorPeriod_CostRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_CostRanking_Delta < 0) {
      return (
        <span className="colRed">
          &darr; {row.PriorPeriod_CostRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_CostRanking_Delta === null) {
      return <span>-</span>;
    } else {
      return <span>{row.PriorPeriod_CostRanking_Delta}</span>;
    }
  };
  estimatedProjectFinishChangeVal = (cell, row, rowIndex, formatExtraData) => {
    return <span>{row.Estimated_Project_Finish.slice(0, 10)}</span>;
  };
  durationRankingDeltaChangeVal = (cell, row, rowIndex, formatExtraData) => {
    if (row.PriorPeriod_DurationRanking_Delta > 0) {
      return (
        <span className="colGreen">
          &uarr; {row.PriorPeriod_DurationRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_DurationRanking_Delta < 0) {
      return (
        <span className="colRed">
          &darr; {row.PriorPeriod_DurationRanking_Delta}
        </span>
      );
    } else if (row.PriorPeriod_DurationRanking_Delta === null) {
      return <span>-</span>;
    } else {
      return <span>{row.PriorPeriod_DurationRanking_Delta}</span>;
    }
  };

  fetchRankings() {
    // var periodForRank = this.state.period - 1;
    // if(localStorage.getItem("simStatus")=== "Completed"){
    //   periodForRank = this.state.period;
    // }
    // this.setState({
    //   period : periodForRank
    // });
    UserService.getSimRanking({
      simID: localStorage.getItem("simID"),
      period: this.state.period,
    })
      .then((response) => {
        // var projId = localStorage.getItem("simulation");
        var userRankData = {};
        // for (var i = 0; i <= response.data.length; i++) {
        //   if (response.data[i].ProjectID == projId) {
        //     userRankData = response.data[i];
        //     break;
        //   }
        // }
        if (response.data.length > 0) {
          this.setState({
            ranking: response.data,
            userRank: userRankData,
            showRankTabs: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  handleClick(currentTab) {
    var tempVar = this.state.data;
    tempVar.forEach((item, i) => {
      currentTab === i ? (item.active = true) : (item.active = false);
    });

    this.setState({ currentTab, data: tempVar });
  }

  handlePeriodChange(event) {
    this.setState({ period: event.target.value }, this.fetchRankings);
  }

  render() {
    return (
      <div className="inlineFlex">
        <SideBarAdmin></SideBarAdmin>
        <div className="resourceTable overflowAutoScroll">
          <div>
            <div style={{ width: "100%" }}>
              <h5>Ranking</h5>

              <label>
                {" "}
                Displaying ranks for period : &nbsp;
                <select
                  id="period"
                  name="period"
                  value={this.state.period}
                  onChange={this.handlePeriodChange}
                >
                  {this.state.periodList.map((val, i) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            {this.state.showRankTabs ? (
              <div className="overflowAutoScroll">
                <div className="tab">
                  {this.state.data.map((button, i) => (
                    <button
                      key={button.name}
                      className={button.active ? "tablinks active" : "tablinks"}
                      onClick={() => this.handleClick(i)}
                    >
                      {button.name}
                    </button>
                  ))}
                </div>

                <div className="tabcontent overflowAutoScroll">
                  {this.state.currentTab === 0 && (
                    <div>
                      <p>{this.state.data[this.state.currentTab].desc}</p>
                      <div className="mb10">
                        The below table displays the overall ranking for all
                        teams playing the simulation.
                      </div>

                      <BootstrapTable
                        striped
                        hover
                        keyField="ProjectID"
                        data={this.state.ranking}
                        columns={this.state.overallRankingColumns}
                      ></BootstrapTable>
                    </div>
                  )}

                  {this.state.currentTab === 1 && (
                    <div>
                      <p>{this.state.data[this.state.currentTab].desc}</p>
                      <div className="mb10">
                        The below table displays the ranking based on work
                        completed for all teams playing the simulation.
                      </div>
                      <BootstrapTable
                        striped
                        hover
                        keyField="ProjectID"
                        data={this.state.ranking}
                        columns={this.state.workRankingColumns}
                      ></BootstrapTable>
                    </div>
                  )}

                  {this.state.currentTab === 2 && (
                    <div>
                      <p>{this.state.data[this.state.currentTab].desc}</p>
                      <div className="mb10">
                        The below table displays the ranking based on cost for
                        all teams playing the simulation.
                      </div>
                      <BootstrapTable
                        striped
                        hover
                        keyField="ProjectID"
                        data={this.state.ranking}
                        columns={this.state.costRankingColumns}
                      ></BootstrapTable>
                    </div>
                  )}

                  {this.state.currentTab === 3 && (
                    <div>
                      <p>{this.state.data[this.state.currentTab].desc}</p>
                      <div className="mb10">
                        The below table displays the ranking based on duration
                        for all teams playing the simulation.
                      </div>
                      <BootstrapTable
                        striped
                        hover
                        keyField="ProjectID"
                        data={this.state.ranking}
                        columns={this.state.durationRankingColumns}
                      ></BootstrapTable>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <label>There are no rankings available for this period.</label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Nav, Navbar } from "react-bootstrap";
import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import Metrics from "./components/metrics.component";
import StudentUser from "./components/board-student.component";
import BoardAdmin from "./components/board-admin.component";
import ResourcesList from "./components/resources-list.component";
import iActionList from "./components/iaction-list.component"
import HiredResourcesList from "./components/hired-resources-list.component";
import OnboardingResourcesList from "./components/onboarding-resources-list.component";
import TeamResourcesList from "./components/team-resources-list.component";
import TaskList from "./components/task-list.component";
import UpdatePassword from "./components/update-password";
import RecoverPassword from "./components/recover-password";
import PrivateRoute from "./components/privateRoute";
import AssignTasks from "./components/assign-tasks.component";
import Ranking from "./components/ranking.component";
import SimBackground from "./components/background.component";
import SimBackgroundAsync from "./components/background_async.component";
import SimGuide from "./components/simguide.component";
import GanttChart from "./components/gantt-chart.component";
import AdminDetail from "./components/detail-admin";
import AdminDetailAsync from "./components/detail-admin-async";
import RankingAdmin from "./components/ranking-admin.component";
import MetricsAdmin from "./components/metrics-admin.component";
import ChangePassword from "./components/changepassword.component";


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showAdminBoard: false,
      currentUser: undefined,
    };
  }
  handleClick(e) {
    this.setState({
      showDD: !this.state.showDD,
    });
    e.preventDefault();
  }
  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    this.setState({
      currentUser: null,
      teamID: 0,
    });
    AuthService.logout();
  }

  render() {
    const { currentUser } = this.state;
    return (
      <React.Fragment>
        <Router>
          <Navbar expand="lg" className="topNav">
            <Navbar.Brand href="/">Project Management Game</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {currentUser ? (
                <Nav className="ml-auto">
                  {this.state.showAdminBoard ? (
                    <Nav.Item>
                      <Nav.Link as={Link} to="/admin" className="topNavLink">
                        Admin
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <Nav.Item></Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link as={Link} to="/simguide" className="topNavLink">
                      Sim Guide
                    </Nav.Link>
                  </Nav.Item>
                  {this.state.showAdminBoard ? (
                    <Nav.Item></Nav.Item>
                  ) : (
                    <Nav.Item>
                      <Nav.Link as={Link} to="/profile" className="topNavLink">
                        Profile
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="/login"
                      onClick={this.logOut}
                      className="topNavLink"
                    >
                      LogOut
                    </Nav.Link>
                  </Nav.Item>



                </Nav>

                
                
                
              ) : (
                <Nav className="ml-auto">
                  <Nav.Item>
                    <Nav.Link as={Link} to="/login" className="topNavLink">
                      LogIn
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={Link} to="/register" className="topNavLink">
                      Register
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              )}
            </Navbar.Collapse>
          </Navbar>

          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route path="/updatePassword" component={UpdatePassword} />
            <Route path="/forgotPassword" component={RecoverPassword} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/simguide" component={SimGuide} />
            <PrivateRoute path="/student" component={StudentUser} />
            <PrivateRoute path="/admin" component={BoardAdmin} />
            <Route path="/changePassword" component={ChangePassword} />
            <Route exact path="/metrics" component={Metrics} />
            <Route exact path="/resources" component={ResourcesList} />
            <Route exact path="/iLeadershipActions" component={iActionList} />
            <Route
              exact
              path={["/hiredResources"]}
              component={HiredResourcesList}
            />
            <Route
              path="/onboardingResources"
              component={OnboardingResourcesList}
            />
            <Route path="/teamResources" component={TeamResourcesList} />
            <Route exact path="/tasks" component={TaskList} />
            <Route path="/ganttChart" component={GanttChart} />
            <Route path="/taskAssignment" component={AssignTasks} />
            <Route path="/ranking" component={Ranking} />
            <Route path="/status" component={SimBackground} />
            <Route path="/async_status" component={SimBackgroundAsync} />
            <Route path="/detail" component={AdminDetail} />
            <Route path="/async_detail" component={AdminDetailAsync} />
            <Route path="/ranking-admin" component={RankingAdmin} />
            <Route path="/metrics-admin" component={MetricsAdmin} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;

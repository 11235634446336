import React, { Component } from "react";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "Welcome to Project Management Simulation!",
    };
  }

  render() {
    return (
      <div className="p20">
        <h3>{this.state.content}</h3>
        <p className="lead">
          In this Project Management Simulation, students will take on the role
          of a project manager to plan and execute project(s). Through careful
          planning and execution of the project, the students should be able to
          complete the project on time and within budget.
        </p>
        <hr className="my-4" />
        <p className="lead">
          This experiential learning tool has been developed for Project
          Management course.
        </p>
        <div className="logoBackground"></div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";

// MOVE TO REDUX
import axios from "axios";

class RecoverPassword extends Component {
  state = {
    email: "",
    submitted: false,
  };

  handleChange = (e) => {
    this.setState({ email: e.target.value });
  };

  sendPasswordResetEmail = (e) => {
    e.preventDefault();
    const { email } = this.state;
    axios.post(`http://localhost:8080/reset_password/${email}`);
    this.setState({ email: "", submitted: true });
  };

  render() {
    const { email, submitted } = this.state;

    return (
      <div>
        <h3>Reset your password</h3>
        {submitted ? (
          <div className="reset-password-form-sent-wrapper">
            <p>
              If that account is in our system, we emailed you a link to reset
              your password.
            </p>
            <Link to="/login" className="ghost-btn">
              Return to sign in
            </Link>
          </div>
        ) : (
          <div className="reset-password-form-wrapper">
            <p>
              Enter your email and we'll send you password reset instructions.
            </p>
            <form onSubmit={this.sendPasswordResetEmail}>
              <input
                type="email"
                onChange={this.handleChange}
                value={email}
                placeholder="Email address"
                required
              />
              <div style={{ marginTop: "15px" }}>
                <button className="btn-primary password-reset-btn">
                  Send password reset email
                </button>
              </div>
            </form>
            <Link to="/login">I remember my password</Link>
          </div>
        )}
      </div>
    );
  }
}

export default RecoverPassword;

import axios from "axios";
import authHeader from "./auth-header";

class TaskService {
  displayTasks(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/displayTasks",
      data,
      { headers: authHeader() }
    );
  }

  viewTaskList(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/viewTaskList",
      data,
      { headers: authHeader() }
    );
  }

  saveTaskAssignment(data) {
    console.log(21)
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/saveTaskAssignment",
      data,
      { headers: authHeader() }
    );
  }

  fetchPeriodAssignments(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/fetchPeriodAssignments",
      data,
      { headers: authHeader() }
    );
  }

  getTaskAssignmentWithResourceName(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getTaskAssignmentWithResourceName",
      data,
      { headers: authHeader() }
    );
  }
}

export default new TaskService();

import axios from "axios";
import authHeader from "./auth-header";

class ResourceDataService {
  retrieveResources(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/retrieveResources",
      data,
      { headers: authHeader() }
    );
  }

  updateResource(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/updateResource",
      data,
      { headers: authHeader() }
    );
  }
}

export default new ResourceDataService();

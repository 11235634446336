import React, { Component } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Modal from "react-bootstrap/Modal";
import ChangePassword from "../components/changepassword.component";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.enterSim = this.enterSim.bind(this);
    this.viewProjectDashboard = this.viewProjectDashboard.bind(this);

    this.state = {
      selectedProjectName: "",
      simID: 0,
      teamID: 0,
      selectedProjectId: 0,
      currentPeriod: 0,
      desc: "",
      isChangePasswordOpen: false,
      isModalOpen: false,
      currentUser: AuthService.getCurrentUser(),
      currentUserId: JSON.parse(localStorage.getItem("user")).id,
      projects: [],
      columns: [
        { dataField: "ID", text: "ID" },
        { dataField: "Name", text: "Name", filter: textFilter() },
        {
          dataField: "Title",
          text: "Title",
        },
        {
          dataField: "Description",
          text: "Description",
        },
        {
          dataField: "ScheduleFrom",
          text: "ScheduleFrom",
        },
        {
          dataField: "StartDate",
          text: "StartDate",
        },
        {
          dataField: "FinishDate",
          text: "FinishDate",
        },
        {
          dataField: "CurrentPeriod",
          text: "Period",
        },
        {
          dataField: "Budget",
          text: "Budget",
        },
        {
          dataField: "Enter",
          text: "Enter",
          formatter: this.linkEnter,
        },
      ],

      
    };
  }

  openChangePassword = () => {
    this.props.history.push("/changePassword");
  };
  
  

  componentDidMount() {
    localStorage.setItem("simulation", 0);
    UserService.getUserProjects(this.state.currentUserId)
      .then((response) => {
        var tempData = response.data;
        tempData.forEach((item, i) => {
          if (item.FinishDate) {
            item.FinishDate = item.FinishDate.slice(0, 10);
          }
          if (item.StartDate) {
            item.StartDate = item.StartDate.slice(0, 10);
          }
        });

        this.setState({
          projects: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  linkEnter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        onClick={() => {
          this.enterSim(row);
        }}
      >
        Enter
      </button>
    );
  };
  enterSim(row) {
    localStorage.setItem("decisionsSubmitted", row.PeriodStatus);
    this.setState({
      isModalOpen: true,
      selectedProjectName: row.Name,
      selectedProjectId: row.ID,
      currentPeriod: row.CurrentPeriod,
      simID: row.SimID,
      teamID: row.TeamID,
      desc: row.Description,
    });
  }
  closeModal() {
    this.setState({ isModalOpen: false });
  }
  viewProjectDashboard() {
    localStorage.setItem("simulation", this.state.selectedProjectId);
    localStorage.setItem("simulationName", this.state.selectedProjectName);
    localStorage.setItem("teamID", this.state.teamID);
    localStorage.setItem("currentPeriod", this.state.currentPeriod);
    localStorage.setItem("simID", this.state.simID);
    localStorage.setItem("desc", this.state.desc);
    if (this.state.desc === "Async") {
      this.props.history.push("/async_status");
    } else {
      this.props.history.push("/status");
    }


  }
  render() {
    const { currentUser } = this.state;

    return (
      <div className="p20">
        <Modal show={this.state.isModalOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm simulation</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Are you sure you want to enter {this.state.selectedProjectName}?
            </p>
          </Modal.Body>

          <Modal.Footer>
            <button variant="secondary" onClick={this.closeModal}>
              No
            </button>
            <button variant="primary" onClick={this.viewProjectDashboard}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>

        <header>
          <h3>
            <strong>Welcome {currentUser.username}!</strong>
            
          </h3>

    
        </header>
        <button
          style={{
            padding: '4px',
            cursor: 'pointer',
            float: 'right',
            marginTop: '-11px'
          }}
          onClick={this.openChangePassword}
        >
          Change Password
        </button>

        
        <p className="">Select a simulation to begin.</p>
        <div className="overflowAutoScroll width100">
          <BootstrapTable
            striped
            bordered
            hover
            keyField="ID"
            data={this.state.projects}
            columns={this.state.columns}
            filter={filterFactory()}
          ></BootstrapTable>
        </div>
      </div>
    );
  }
}

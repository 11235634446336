import React, { Component } from "react";
import SideBar from "./side-bar.js";
import UserService from "../services/user.service";
import BootstrapTable from "react-bootstrap-table-next";

export default class Metrics extends Component {
  constructor(props) {
    super(props);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.state = {
      simulationId: localStorage.getItem("simulation"),
      simulationName: localStorage.getItem("simulationName"),
      period: localStorage.getItem("currentPeriod"),
      selectedPeriod: localStorage.getItem("currentPeriod") - 1,
      isLoading: true,
      projectSummary: [],
      periodList: [],
      resourceDetail: [],
      costDetail: [],
      workDetail: [],
      resourceColumns: [
        {
          dataField: "resourcename",
          text: "Resource Name",
          sort: true,
        },
        {
          dataField: "RegionName",
          text: "Region",
          sort: true,
        },
        {
          dataField: "HourlyRate",
          text: "Hourly Rate",
          sort: true,
        },
        {
          dataField: "WorkActualCompleted",
          text: "Work Actual Completed",
          sort: true,
        },
        {
          dataField: "assignedActions",
          text: "Actions Assigned",
          sort: true,
        },
      ],
      costColumns: [
        {
          dataField: "PeriodNum",
          text: "Period",
          sort: true,
        },
        {
          dataField: "NumberofResources",
          text: "No. of resources",
          sort: true,
        },
        {
          dataField: "TotalCosts",
          text: "Total Cost",
          sort: true,
        },
        {
          dataField: "ActionCosts",
          text: "ActionCosts",
          sort: true,
        },
        {
          dataField: "AverageRate",
          text: "Average Rate",
          sort: true,
          formatter: (cell) => Number(cell).toFixed(2),
        },
      ],
      workColumns: [
        {
          dataField: "PeriodNum",
          text: "Period",
          sort: true,
        },
        {
          dataField: "Work",
          text: "Work",
          sort: true,
        },
        {
          dataField: "WorkActual",
          text: "Work Actual",
          sort: true,
        },
        {
          dataField: "PercentCompleteDisplay",
          text: "% Complete",
          sort: true,
        },
      ],
    };
  }

  componentDidMount() {
    var tempPeriodList = [];
    var tempPeriod = this.state.period - 1;

    if (localStorage.getItem("simStatus") === "Completed") {
      tempPeriod = this.state.period;
      this.setState({
        selectedPeriod: localStorage.getItem("currentPeriod"),
      });
    } else {
      this.setState({
        selectedPeriod: localStorage.getItem("currentPeriod") - 1,
      });
    }
    for (var i = 1; i <= tempPeriod; i++) {
      tempPeriodList.push(i);
    }
    this.setState({ periodList: tempPeriodList });

    const metricProjSummary = UserService.metricProjectSummary({
      ID: this.state.simulationId,
    })
      .then((response) => {
        var tempProjSummary = response.data[0];
        tempProjSummary.EndDate = response.data[0].EndDate.slice(0, 10);
        tempProjSummary.StartDate = response.data[0].StartDate.slice(0, 10);
        tempProjSummary.Budget =
          "$" + Number(response.data[0].Budget).toLocaleString("en");
        tempProjSummary.BudgetRemaining =
          "$" + Number(response.data[0].BudgetRemaining).toLocaleString("en");
        tempProjSummary.TotalCosts =
          "$" + Number(response.data[0].TotalCosts).toLocaleString("en");
        this.setState({
          projectSummary: tempProjSummary,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    const metricCost = UserService.metricCostDetail({
      projID: this.state.simulationId,
    })
      .then((response) => {
        this.setState({
          costDetail: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const metricWork = UserService.metricWorkDetail({
      projID: this.state.simulationId,
    })
      .then((response) => {
        this.setState({
          workDetail: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const metricResource = UserService.metricResourceDetail({
      projID: this.state.simulationId,
      period: tempPeriod,
    })
      .then((response) => {
        this.setState({
          resourceDetail: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    Promise.all([
      metricProjSummary,
      metricCost,
      metricWork,
      metricResource,
    ]).then(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  handlePeriodChange(event) {
    this.setState(
      { selectedPeriod: event.target.value, isLoading: true },
      this.loadMetricForPeriod
    );
  }

  loadMetricForPeriod() {
    UserService.metricResourceDetail({
      projID: this.state.simulationId,
      period: this.state.selectedPeriod,
    })
      .then((response) => {
        this.setState({
          resourceDetail: response.data,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="inlineFlex">
        <SideBar></SideBar>
        <div className="p20 width100">
          <h5>Metrics</h5>
          <div className={this.state.isLoading ? "summaryLoader" : ""}></div>
          <div className="p10 mt10" style={{ backgroundColor: "#f5f5f5" }}>
            <div className="row">
              <div className="col-md-6" style={{ padding: "0px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <span className="metricsWidgetHeading"> Period </span>
                  <div className="metricsWidgetBody">
                    <strong>No. of periods completed: </strong>
                    {this.state.projectSummary.NumberofPeriodsCompleted}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Percentage Completed: </strong>
                    {
                      this.state.projectSummary
                        .PercentagePeriodsCompletedDisplay
                    }
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Total no. of periods: </strong>
                    {this.state.projectSummary.TotalProjectPeriods}
                  </div>
                </div>
              </div>

              <div className="col-md-6" style={{ padding: "0px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <span className="metricsWidgetHeading"> Duration </span>
                  <div className="metricsWidgetBody">
                    <strong>Start Date: </strong>
                    {this.state.projectSummary.StartDate}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>End Date: </strong>
                    {this.state.projectSummary.EndDate}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Duration: </strong>
                    {this.state.projectSummary.Duration_Calc}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ padding: "0px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <span className="metricsWidgetHeading"> Financials </span>

                  <div className="metricsWidgetBody">
                    <strong>Budget: </strong>
                    {this.state.projectSummary.Budget}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Total Costs: </strong>
                    {this.state.projectSummary.TotalCosts}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Budget Remaining: </strong>
                    {this.state.projectSummary.BudgetRemaining}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Budget Utilization: </strong>
                    {this.state.projectSummary.PercentBudgetRemainingDisplay}
                  </div>
                  {this.state.costDetail.length === 0 ? null : (
                    <BootstrapTable
                      id="metricResourceTable"
                      striped
                      bordered
                      hover
                      keyField="ResourceID"
                      data={this.state.costDetail}
                      columns={this.state.costColumns}
                    ></BootstrapTable>
                  )}
                </div>
              </div>

              <div className="col-md-6" style={{ padding: "0px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <span className="metricsWidgetHeading"> Work </span>
                  <div className="metricsWidgetBody">
                    <strong>Work: </strong>
                    {this.state.projectSummary.Work}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Actual Work: </strong>
                    {this.state.projectSummary.WorkActual}
                  </div>
                  <div className="metricsWidgetBody">
                    <strong>Percentage Completed: </strong>
                    {this.state.projectSummary.PercentCompleteDisplay}
                  </div>
                  <div
                    className="metricsWidgetBody"
                    style={{ height: "31px" }}
                  ></div>

                  {this.state.workDetail.length === 0 ? null : (
                    <BootstrapTable
                      id="metricResourceTable"
                      striped
                      bordered
                      hover
                      keyField="ResourceID"
                      data={this.state.workDetail}
                      columns={this.state.workColumns}
                    ></BootstrapTable>
                  )}
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{
                backgroundColor: "white",
                margin: "5px",
                padding: "10px",
              }}
            >
              <div className="metricsWidgetHeading">
                {" "}
                Resource Detail for period : &nbsp;{" "}
              </div>
              <select
                id="period"
                name="period"
                value={this.state.selectedPeriod}
                onChange={this.handlePeriodChange}
              >
                {this.state.periodList.map((val, i) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))}
              </select>

              {this.state.resourceDetail.length === 0 ? (
                <div className="width100">
                  There is no resource detail for period{" "}
                  {this.state.selectedPeriod}.
                </div>
              ) : (
                <div className="width100" style={{ marginTop: "10px" }}>
                  <BootstrapTable
                    id="metricResourceTable"
                    striped
                    bordered
                    hover
                    keyField="ResourceID"
                    data={this.state.resourceDetail}
                    columns={this.state.resourceColumns}
                  ></BootstrapTable>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

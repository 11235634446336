import React, { Component } from "react";
import ResourceDataService from "../services/resource.service";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import SideBar from "./side-bar.js";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";

// lagecy selector
// const selectRegionsOptions = {
//   X: "X",
//   Y: "Y",
//   Z: "Z",
// };
const exportColumns = [
  { dataField: "resourceName", text: "Name" },
  { dataField: "resourceCategory", text: "Category" },
  {
    dataField: "RegionName",
    text: "Region",
  },
  {
    dataField: "resourceRate",
    text: "Rate",
  },
  {
    dataField: "resourceTraining",
    text: "Training",
  },
  {
    dataField: "resourceSkill",
    text: "Skill",
  },
  {
    dataField: "resourceExp",
    text: "Experience",
  },
  {
    dataField: "resourceEducation",
    text: "Education",
  },
];
const { ExportCSVButton } = CSVExport;

export default class ResourcesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      isLoading: true,
      columns: [
        {
          dataField: "resourceName",
          text: "Name",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "resourceCategory",
          text: "Category",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "RegionName",
          text: "Region",
          sort: true,
          filter: textFilter(),
          // formatter: (cell) => selectRegionsOptions[cell],
          // filter: selectFilter({
          //   options: selectRegionsOptions,
          //   placeholder: "Select",
          // }),
        },
        {
          dataField: "resourceRate",
          text: "Rate",
          sort: true,
        },
        {
          dataField: "resourceTraining",
          text: "Training",
          sort: true,
        },
        {
          dataField: "resourceSkill",
          text: "Skill",
          sort: true,
        },
        {
          dataField: "resourceExp",
          text: "Experience",
          sort: true,
        },
        {
          dataField: "resourceEducation",
          text: "Education",
          sort: true,
        },

        {
          dataField: "Resource_Satisfaction",
          text: "Satisfaction",
          sort: true,
        }

      ],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    ResourceDataService.retrieveResources({
      status: "Onboarding",
      projectId: localStorage.getItem("simulation"),
    })
      .then((response) => {
        this.setState({
          resources: response.data,
          isLoading: false,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="inlineFlex">
        <SideBar></SideBar>
        <div className="resourceTable overflowAutoScroll">
          <div style={{ width: "100%" }}>
            <h5>Onboarding Resources List</h5>
          </div>
          {this.state.resources.length === 0 ? (
            <div>You do not have any resources being onboarded.</div>
          ) : (
            <div>
              {this.state.isLoading ? (
                <div className="loader"></div>
              ) : (
                <ToolkitProvider
                  keyField="resourceID"
                  data={this.state.resources}
                  columns={exportColumns}
                  exportCSV={{ fileName: "OnboardingResources.csv" }}
                >
                  {(props) => (
                    <div>
                      <div className="resourceBtnTable">
                        <span style={{ float: "left" }}>
                          {" "}
                          <p>
                            Listed below are the resources that are currently
                            being onboarded.
                          </p>{" "}
                        </span>
                        <span style={{ float: "right" }}>
                          <ExportCSVButton
                            {...props.csvProps}
                            className="csvExportButton"
                          >
                            Export to CSV
                          </ExportCSVButton>
                        </span>
                      </div>
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          striped
                          bordered
                          hover
                          keyField="resourceID"
                          data={this.state.resources}
                          columns={this.state.columns}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                        ></BootstrapTable>
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import ResourceDataService from "../services/resource.service";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import Modal from "react-bootstrap/Modal";
import SideBar from "./side-bar.js";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";

// lagecy selector
// const selectRegionsOptions = {
//   X: "X",
//   Y: "Y",
//   Z: "Z",
// };
const exportColumns = [
  { dataField: "resourceName", text: "Name" },
  { dataField: "resourceCategory", text: "Category" },
  {
    dataField: "RegionName",
    text: "Region",
  },
  {
    dataField: "resourceRate",
    text: "Rate",
  },
  {
    dataField: "resourceTraining",
    text: "Training",
  },
  {
    dataField: "resourceSkill",
    text: "Skill",
  },
  {
    dataField: "resourceExp",
    text: "Experience",
  },
  {
    dataField: "resourceEducation",
    text: "Education",
  },
  {
    dataField: "resourceSatisfaction",
    text: "Satisfaction",
  }
];
const { ExportCSVButton } = CSVExport;

export default class HiredResourcesList extends Component {
  constructor(props) {
    super(props);
    this.retrieveResources = this.retrieveResources.bind(this);
    this.releaseResource = this.releaseResource.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      selectedResourceID: 0,
      selectedResourceName: "",
      isModalOpen: false,
      resources: [],
      removeColumnFlag: false,
      isLoading: true,
      columns: [
        {
          dataField: "resourceName",
          text: "Name",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "resourceCategory",
          text: "Category",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "RegionName",
          text: "Region",
          sort: true,
          filter: textFilter(),
          // formatter: (cell) => selectRegionsOptions[cell],
          // filter: selectFilter({
          //   options: selectRegionsOptions,
          //   placeholder: "Select",
          // }),
        },
        {
          dataField: "resourceRate",
          text: "Rate",
          sort: true,
        },
        {
          dataField: "resourceTraining",
          text: "Training",
          sort: true,
        },
        {
          dataField: "resourceSkill",
          text: "Skill",
          sort: true,
        },
        {
          dataField: "resourceExp",
          text: "Experience",
          sort: true,
        },
        {
          dataField: "resourceEducation",
          text: "Education",
          sort: true,
        },
        {
          dataField: "Resource_Satisfaction",
          text: "Satisfaction",
          sort: true,
        },
      ],
    };
    this.removeResource.bind(this);
  }

  componentDidMount() {
    this.retrieveResources();
  }

  removeResource(row) {
    this.setState({
      isModalOpen: true,
      selectedResourceID: row.resourceID,
      selectedResourceName: row.resourceName,
    });
  }

  linkRemove = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        onClick={() => {
          this.removeResource(row);
        }}
      >
        Remove
      </button>
    );
  };

  closeModal() {
    this.setState({ isModalOpen: false });
  }
  releaseResource() {
    ResourceDataService.updateResource({
      projectId: localStorage.getItem("simulation"),
      resourceId: this.state.selectedResourceID,
      period: localStorage.getItem("currentPeriod"),
      status: "Available",
    })
      .then((response) => {
        this.setState({ isModalOpen: false });
        this.retrieveResources();
        console.log(response.data);
        this.setState({
          message: "The resource was updated successfully!",
        });
      })
      .catch((e) => {
        this.setState({ isModalOpen: false });
        console.log(e);
      });
  }
  retrieveResources() {
    this.setState({
      isLoading: true,
    });
    ResourceDataService.retrieveResources({
      status: "On Team",
      projectId: localStorage.getItem("simulation"),
    })
      .then((response) => {
        if (
          localStorage.getItem("decisionsSubmitted") === "0" &&
          this.state.removeColumnFlag === false
        ) {
          var tempColumns = this.state.columns;
          tempColumns.push({
            dataField: "Remove",
            text: "Remove",
            formatter: this.linkRemove,
          });
          this.setState({
            columns: tempColumns,
            removeColumnFlag: true,
          });
        }
        this.setState({
          resources: response.data,
          isLoading: false,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="inlineFlex">
        <SideBar></SideBar>
        <div className="resourceTable overflowAutoScroll">
          <Modal show={this.state.isModalOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm release</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                Releasing a resource will delete any task assignment in the
                current period for selected resource. Are you sure you want to
                release {this.state.selectedResourceName}?
              </p>
            </Modal.Body>

            <Modal.Footer>
              <button variant="secondary" onClick={this.closeModal}>
                No
              </button>
              <button variant="primary" onClick={this.releaseResource}>
                Yes
              </button>
            </Modal.Footer>
          </Modal>

          <div style={{ width: "100%" }}>
            <h5>Team Resources List</h5>
          </div>
          {this.state.resources.length === 0 ? (
            <div>
              There are no resources in your virtual team. Hire resources to add
              them to your team.
            </div>
          ) : (
            <div>
              {this.state.isLoading ? (
                <div className="loader"></div>
              ) : (
                <ToolkitProvider
                  keyField="resourceID"
                  data={this.state.resources}
                  columns={exportColumns}
                  exportCSV={{ fileName: "TeamResources.csv" }}
                >
                  {(props) => (
                    <div>
                      <div className="resourceBtnTable">
                        <span style={{ float: "left" }}>
                          {" "}
                          <p>
                            Listed below are the resources on your team. Click
                            the Remove button to release resource.
                          </p>{" "}
                        </span>
                        <span style={{ float: "right" }}>
                          <ExportCSVButton
                            {...props.csvProps}
                            className="csvExportButton"
                          >
                            Export to CSV
                          </ExportCSVButton>
                        </span>
                      </div>
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          striped
                          bordered
                          hover
                          keyField="resourceID"
                          data={this.state.resources}
                          columns={this.state.columns}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                        ></BootstrapTable>
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL + "/auth/";

class AuthService {
  login(username, password) {
    debugger
    return axios
      .post(API_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("simulation", 0);
        }

        return response.data;
      });
  }

  changePassword(username, password) {
    debugger;
    return axios.post(
      API_URL + "changePassword",
      {
        username,
        password
      },
    );
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("simulation");
    localStorage.removeItem("simulationName");
    localStorage.removeItem("simID");
    localStorage.removeItem("currentPeriod");
    localStorage.removeItem("decisionsSubmitted");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();

import React, { Component } from "react";
import ResourceDataService from "../services/resource.service";
import Modal from "react-bootstrap/Modal";
import SideBar from "./side-bar.js";
import Chevron from "./chevron.js";
import TaskService from "../services/task.service";
import "../accordion.css";

export default class AssignTasks extends Component {
  constructor(props) {
    super(props);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.expandAllAccordions = this.expandAllAccordions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitFun = this.submitFun.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveFunction = this.saveFunction.bind(this);
    this.state = {
      isModalOpen: false,
      isLoading: true,
      teamResources: [],
      savedTasks: [],
      accordionButtonLabel: "Collapse All",
      tasks: [],
      disabledFlag: false,
    };
  }

  toggleAccordion(e) {
    var selectedDiv = e.target.parentElement.parentElement.id;
    this.state.teamResources.forEach(function (res, i) {
      if (res.resourceID == selectedDiv) {
        let tempResData = [...this.state.teamResources];
        tempResData[i].setActive === ""
          ? (tempResData[i].setActive = "active")
          : (tempResData[i].setActive = "");
        tempResData[i].setHeight === "0px"
          ? (tempResData[i].setHeight = "130px")
          : (tempResData[i].setHeight = "0px");
        tempResData[i].setRotate === "accordion__icon"
          ? (tempResData[i].setRotate = "accordion__icon rotate")
          : (tempResData[i].setRotate = "accordion__icon");
        this.setState({
          teamResources: tempResData,
        });
      }
    }, this);
  }

  expandAllAccordions() {
    if (this.state.accordionButtonLabel == "Collapse All") {
      this.setState({ accordionButtonLabel: "Expand All" });
      this.state.teamResources.forEach(function (resource, i) {
        let tempResourceData = [...this.state.teamResources];
        tempResourceData[i].setActive = "";
        tempResourceData[i].setHeight = "0px";
        tempResourceData[i].setRotate = "accordion__icon";
        this.setState({
          teamResources: tempResourceData,
        });
      }, this);
    } else {
      this.setState({ accordionButtonLabel: "Collapse All" });
      this.state.teamResources.forEach(function (resource, i) {
        let tempResourceData = [...this.state.teamResources];
        tempResourceData[i].setActive = "active";
        tempResourceData[i].setHeight = "130px";
        tempResourceData[i].setRotate = "accordion__icon rotate";
        this.setState({
          teamResources: tempResourceData,
        });
      }, this);
    }
  }

  componentDidMount() {
    if (localStorage.getItem("decisionsSubmitted") === "1") {
      this.setState({
        disabledFlag: true,
      });
    }
    TaskService.fetchPeriodAssignments({
      projectId: localStorage.getItem("simulation"),
      period: localStorage.getItem("currentPeriod"),
    })
      .then((response) => {
        let prevAssignment = response.data;
        ResourceDataService.retrieveResources({
          status: "On Team",
          projectId: localStorage.getItem("simulation"),
        })
          .then((response) => {
            this.setState({
              teamResources: response.data,
              isLoading: false,
            });
            response.data.forEach(function (data, i) {
              let tempResourceData = [...this.state.teamResources];
              tempResourceData[i].setActive = "active";
              tempResourceData[i].setHeight = "130px";
              tempResourceData[i].setRotate = "accordion__icon rotate";

              var tempData = {
                resourceId: data.resourceID,
                resourceName: data.resourceName,
                priority1: "",
                priority2: "",
                priority3: "",
                period: localStorage.getItem("currentPeriod"),
              };
              prevAssignment.forEach(function (tempObj, index) {
                if (tempResourceData[i].resourceID == tempObj.ResourceID) {
                  switch (tempObj.Priority) {
                    case 1:
                      tempResourceData[i].priority1 = tempObj.TaskListName;
                      break;
                    case 2:
                      tempResourceData[i].priority2 = tempObj.TaskListName;
                      break;
                    case 3:
                      tempResourceData[i].priority3 = tempObj.TaskListName;
                      break;
                    default:
                      break;
                  }
                }
                if (tempObj.ResourceID == data.resourceID) {
                  switch (tempObj.Priority) {
                    case 1:
                      tempData.priority1 = tempObj.ProjectTaskID;
                      break;
                    case 2:
                      tempData.priority2 = tempObj.ProjectTaskID;
                      break;
                    case 3:
                      tempData.priority3 = tempObj.ProjectTaskID;
                      break;
                    default:
                      break;
                  }
                }
              });
              let tempArr = [...this.state.savedTasks];
              tempArr[i] = tempData;
              this.setState({
                savedTasks: tempArr,
                teamResources: tempResourceData,
              });
            }, this);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });

    TaskService.viewTaskList({
      projectId: localStorage.getItem("simulation"),
    })
      .then((response) => {
        this.setState({
          tasks: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleChange(e, priority) {
    let selectedVal = e.target.value;
    let selectedTaskID = "";
    this.state.tasks.forEach(function (task, i) {
      if (task.TaskListName === selectedVal) {
        selectedTaskID = task.ID;
      }
    });
    let selectedResourceID =
      e.target.parentElement.parentElement.parentElement.parentElement
        .parentElement.id;
    let tempArr = [...this.state.savedTasks];
    tempArr.forEach(function (tempObj, i) {
      if (tempObj.resourceId == selectedResourceID) {
        switch (priority) {
          case 1:
            tempObj.priority1 = selectedTaskID;
            break;
          case 2:
            tempObj.priority2 = selectedTaskID;
            break;
          case 3:
            tempObj.priority3 = selectedTaskID;
            break;
          default:
            break;
        }
      }
    });
    this.setState({
      savedTasks: tempArr,
    });
  }

  submitFun() {
    this.setState({
      isModalOpen: true,
      modalMessage:
        "Are you sure you want to submit the task selection for this period?",
    });
  }

  saveFunction() {
    console.log("Saving...");
    TaskService.saveTaskAssignment({
      projectId: localStorage.getItem("simulation"),
      period: localStorage.getItem("currentPeriod"),
      tasks: this.state.savedTasks,
    })
      .then((response) => {
        this.closeModal();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    this.taskAssignmentDisplay = this.state.teamResources.map(function (
      resource,
      i
    ) {
      return (
        <div
          id={resource.resourceID}
          key={resource.resourceID}
          style={{ paddingBottom: "10px" }}
        >
          <div className="accordion__section" style={{ border: "1px solid" }}>
            <button
              className={`accordion ${resource.setActive}`}
              onClick={(e) => this.toggleAccordion(e)}
            >
              <span className="accordion__title">
                {resource.resourceName} ({resource.resourceCategory})
              </span>
              <Chevron
                className={`${resource.setRotate}`}
                width={10}
                fill={"#777"}
              />
            </button>
            <div
              style={{ maxHeight: `${resource.setHeight}` }}
              className="accordion__content"
            >
              <div className="accordion__text">
                <div style={{ padding: "5px" }}>
                  Priority 1 task :
                  <input
                    style={{
                      marginLeft: "5px",
                      minWidth: "calc(100% - 150px)",
                    }}
                    disabled={this.state.disabledFlag}
                    type="text"
                    placeholder="Select priority 1 task"
                    defaultValue={resource.priority1}
                    list="priority1"
                    onChange={(e) => this.handleChange(e, 1)}
                  />
                  <datalist id="priority1">
                    <select>
                      {this.state.tasks.map(({ ID, TaskListName }, index) => (
                        <option key={ID} id={ID}>
                          {TaskListName}
                        </option>
                      ))}
                    </select>
                  </datalist>
                </div>
                <div style={{ padding: "5px" }}>
                  Priority 2 task :
                  <input
                    style={{
                      marginLeft: "5px",
                      minWidth: "calc(100% - 150px)",
                    }}
                    type="text"
                    disabled={this.state.disabledFlag}
                    placeholder="Select priority 2 task"
                    defaultValue={resource.priority2}
                    list="priority2"
                    onChange={(e) => this.handleChange(e, 2)}
                  />
                  <datalist id="priority2">
                    <select>
                      {this.state.tasks.map(({ ID, TaskListName }, index) => (
                        <option key={ID} id={ID}>
                          {TaskListName}
                        </option>
                      ))}
                    </select>
                  </datalist>
                </div>
                <div style={{ padding: "5px" }}>
                  Priority 3 task :
                  <input
                    style={{
                      marginLeft: "5px",
                      minWidth: "calc(100% - 150px)",
                    }}
                    type="text"
                    disabled={this.state.disabledFlag}
                    placeholder="Select priority 3 task"
                    list="priority3"
                    defaultValue={resource.priority3}
                    onChange={(e) => this.handleChange(e, 3)}
                  />
                  <datalist id="priority3">
                    <select>
                      {this.state.tasks.map(({ ID, TaskListName }, index) => (
                        <option key={ID} id={ID}>
                          {TaskListName}
                        </option>
                      ))}
                    </select>
                  </datalist>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
    this);

    return (
      <div className="inlineFlex">
        <SideBar></SideBar>
        <div className="p20" style={{ width: "100%" }}>
          <Modal show={this.state.isModalOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm task assignment</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Save task assignment?</p>
            </Modal.Body>

            <Modal.Footer>
              <button variant="secondary" onClick={this.closeModal}>
                No
              </button>
              <button variant="primary" onClick={this.saveFunction}>
                Yes
              </button>
            </Modal.Footer>
          </Modal>

          {this.state.isLoading ? (
            <div className="loader"></div>
          ) : (
            <div>
              <div style={{ width: "100%", display: "inline-block" }}>
                <span style={{ float: "left" }}>
                  <h5>Task Assignment</h5>
                  <p>
                    In this page, you will be able to assign task(s) for
                    resource(s) in your virtual team.
                  </p>
                </span>
                <span style={{ float: "right" }}>
                  <button onClick={this.expandAllAccordions}>
                    {this.state.accordionButtonLabel}
                  </button>
                </span>
              </div>
              {this.state.teamResources.length !== 0 ? (
                <div>
                  {this.taskAssignmentDisplay}
                  {this.state.disabledFlag ? null : (
                    <div style={{ marginTop: "15px" }}>
                      Save your task assignment by clicking the 'Save' button.
                      <button
                        style={{ float: "right", padding: "5px 25px" }}
                        onClick={this.submitFun}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  You do not have any resources in your team. Hire resources to
                  assign tasks.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

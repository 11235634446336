import React, { Component } from "react";
import UserService from "../services/user.service";

export default class SimGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simulationId: localStorage.getItem("simulation"),
      simulationName: localStorage.getItem("simulationName"),
    };
  }

  componentDidMount() {
    this.pageLoadCallsFunction();
  }

  pageLoadCallsFunction() {
    UserService.getSimGuideName({ simID: localStorage.getItem("simID") })
      .then((response) => {
        const name = response.data[0]["SimGuide"];
        this.setState({
          guideName: name,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="p20">
        <h5>Simulation Guide</h5>
        <div style={{ height: "calc(100vh - 115px)", weight: "100vh" }}>
          <iframe
            id="simGuide"
            style={{
              border: "1px solid #666CCC",
              height: "100%",
              width: "100%",
            }}
            title="Sim Guide"
            src={
              process.env.PUBLIC_URL + "/Guide/" + this.state.guideName + ".pdf"
            }
            frameBorder="1"
            scrolling="auto"
          ></iframe>
        </div>
      </div>
    );
  }
}

import axios from "axios";
import authHeader from "./auth-header";

class AdminDataService {
  processPeriod(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/processPeriod",
      data,
      { headers: authHeader() }
    );
  }

  processPeriodZero(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/processPeriodZero",
      data,
      { headers: authHeader() }
    );
  }

  getSimulations(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getSimulations",
      data,
      { headers: authHeader() }
    );
  }

  getSubmissionCount(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getSubmissionCount",
      data,
      { headers: authHeader() }
    );
  }

  getUnsubmittedList(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getUnsubmittedList",
      data,
      { headers: authHeader() }
    );
  }

  getDetailAsync(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getDetailAsync",
      data,
      { headers: authHeader() }
    );
  }

  getMaxPeriod(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getMaxPeriod",
      data,
      { headers: authHeader() }
    );
  }

  getSimTeamList(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getSimTeamList",
      data,
      { headers: authHeader() }
    );
  }

  getProjectId(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/getProjectId",
      data,
      { headers: authHeader() }
    );
  }

  resetSimulation(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/resetSimulation",
      data,
      { headers: authHeader() }
    );
  }
}

export default new AdminDataService();

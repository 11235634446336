import React, { Component } from "react";
import SideBar from "./side-bar.js";
import TaskService from "../services/task.service";
import UserService from "../services/user.service";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default class TaskList extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.downloadProjectFile = this.downloadProjectFile.bind(this);
    this.state = {
      simulationId: localStorage.getItem("simulation"),
      simulationName: localStorage.getItem("simulationName"),
      simTasks: [],
      isModalOpen: false,
      isLoading: true,
      submitClick: false,
    };
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  downloadProjectFile() {
    window.location.href =
      process.env.PUBLIC_URL + "/Gantt-Chart/" + this.state.ganttName + ".pod";
  }

  componentDidMount() {
    TaskService.displayTasks({
      projectId: localStorage.getItem("simulation"),
    })
      .then((response) => {
        this.setState({
          simTasks: response.data,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    UserService.getSimGanttName({ simID: localStorage.getItem("simID") })
      .then((response) => {
        const name = response.data[0]["SimGantt"];
        this.setState({
          ganttName: name,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { isLoading } = this.state;
    this.tasksFunction = this.state.simTasks.map(function (task, j) {
      var tempStart = null;
      var tempEnd = null;
      if (task.StartDate !== null) {
        tempStart = task.StartDate.slice(0, 10);
      }
      if (task.EndDate !== null) {
        tempEnd = task.EndDate.slice(0, 10);
      }
      if (task.TaskType === 0) {
        return (
          <tr
            id={task.ID}
            key={j}
            style={{ fontWeight: "bold", backgroundColor: "#cfcfcf" }}
          >
            <td>{task.UID}</td>
            <td>{task.WBS}</td>
            <td>{task.Name}</td>
            <td>{task.Duration_Calc}</td>
            <td>{tempStart}</td>
            <td>{tempEnd}</td>
            <td>{task.Work}</td>
            <td>{task.WorkActual}</td>
            <td>{task.PercentCompleteDisplay}</td>
            <td>{task.Predecessors}</td>
            <td>{task.Assignments}</td>
            <td>{task.NewAssignments}</td>
          </tr>
        );
      } else if (task.TaskType === 1) {
        return (
          <tr
            id={task.ID}
            key={j}
            style={{ fontWeight: "bold", backgroundColor: "#dcdcdc" }}
          >
            <td>{task.UID}</td>
            <td>{task.WBS}</td>
            <td>{task.Name}</td>
            <td>{task.Duration_Calc}</td>
            <td>{tempStart}</td>
            <td>{tempEnd}</td>
            <td>{task.Work}</td>
            <td>{task.WorkActual}</td>
            <td>{task.PercentCompleteDisplay}</td>
            <td>{task.Predecessors}</td>
            <td>{task.Assignments}</td>
            <td>{task.NewAssignments}</td>
          </tr>
        );
      } else {
        return (
          <tr id={task.ID} key={j}>
            <td>{task.UID}</td>
            <td>{task.WBS}</td>
            <td>{task.Name}</td>
            <td>{task.Duration_Calc}</td>
            <td>{tempStart}</td>
            <td>{tempEnd}</td>
            <td>{task.Work}</td>
            <td>{task.WorkActual}</td>
            <td>{task.PercentCompleteDisplay}</td>
            <td>{task.Predecessors}</td>
            <td>{task.Assignments}</td>
            <td>{task.NewAssignments}</td>
          </tr>
        );
      }
    }, this);
    return (
      <div className="inlineFlex">
        <SideBar></SideBar>
        {isLoading ? (
          <div className="loader"></div>
        ) : (
          <div className="resourceTable overflowAutoScroll">
            <div className="width100">
              <h5>Tasks List</h5>
              <div style={{ width: "100%", display: "inline-block" }}>
                <span style={{ float: "left" }}>
                  Listed below are the tasks for the simulation.
                </span>
                <span style={{ float: "right" }}>
                  <button
                    onClick={this.downloadProjectFile}
                    style={{ margin: "0 10px 5px 0" }}
                  >
                    Download Baseline Project
                  </button>
                  <ReactHTMLTableToExcel
                    id="table-xls-button"
                    className="download-table-xls-button"
                    table="taskList"
                    filename="TaskList"
                    sheet="Tasks"
                    buttonText="Export to XLS"
                  />
                </span>
              </div>
            </div>
            <div className="overflowAutoScroll">
              <table className="width100" id="taskList">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>WBS</th>
                    <th>Task Name</th>
                    <th>Duration</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Work</th>
                    <th>Actual Work</th>
                    <th>% Completed</th>
                    <th>Predecessors</th>
                    <th>Prior Period Assignment</th>
                    <th>Current Period Assignment</th>
                  </tr>
                </thead>
                <tbody>{this.tasksFunction}</tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

import axios from "axios";
import authHeader from "./auth-header";

class ActionService {
    retrieveActions() {
      
    return axios.get( process.env.REACT_APP_API_BASE_URL + "/retrieveActions", { headers: authHeader() });
    //   console.log(52);
    //   return axios.get(
    //     process.env.REACT_APP_API_BASE_URL + "/retrieveActions",
    //     // Pass headers as a separate argument, not inside the request body
    //     {},
    //     { headers: authHeader() }
    //   );
     }

     saveActionAssignment(data) {
      return axios.post(
        process.env.REACT_APP_API_BASE_URL + "/saveActionAssignment",
        data,
        { headers: authHeader() }
      );
    }

    GetActionAssignment(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/GetActionAssignment",
      data,
      { headers: authHeader() }
    );
  }
  GetSelectedActionResource(data) {
    return axios.post(
      process.env.REACT_APP_API_BASE_URL + "/GetSelectedActionResource",
      data,
      { headers: authHeader() }
    );
  }
}
    
  
  
  
export default new ActionService();

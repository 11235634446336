import React, { Component } from "react";
import SideBar from "./side-bar.js";
import TaskService from "../services/task.service";
import UserService from "../services/user.service";
import TimeLine from "react-gantt-timeline";

export default class GanttChart extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.config = {
      header: {
        top: {
          style: {
            background: "linear-gradient( grey, black)",
            textShadow: "0.5px 0.5px black",
            fontSize: 12,
          },
        },
        middle: {
          style: {
            backgroundColor: "#990000",
            fontSize: 9,
          },
        },
        bottom: {
          style: {
            background: "linear-gradient( grey, black)",
            fontSize: 9,
            color: "orange",
          },
          selectedStyle: {
            background: "linear-gradient( #d011dd ,#d011dd)",
            fontWeight: "bold",
            color: "white",
          },
        },
      },
      taskList: {
        title: {
          label: "Tasks",
          style: {
            background: "linear-gradient( grey, black)",
          },
        },
        task: {
          style: {
            backgroundColor: "grey",
            color: "white",
          },
        },
        verticalSeparator: {
          style: {
            backgroundColor: "#fbf9f9",
          },
          grip: {
            style: {
              backgroundColor: "red",
            },
          },
        },
      },
      dataViewPort: {
        rows: {
          style: {
            backgroundColor: "white",
            borderBottom: "solid 0.5px silver",
          },
        },
        task: {
          showLabel: true,
          style: {
            borderRadius: 1,
            boxShadow: "2px 2px 8px #888888",
          },
        },
      },
      links: {
        color: "black",
        selectedColor: "#ff00fa",
      },
    };
    this.links = [];
    this.state = {
      simulationId: localStorage.getItem("simulation"),
      simulationName: localStorage.getItem("simulationName"),
      simTasks: [],
      isModalOpen: false,
      isLoading: true,
      submitClick: false,
    };
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  downloadProjectFile() {
    window.location.href =
      process.env.PUBLIC_URL + "/Gantt-Chart/" + this.state.ganttName + ".pod";
  }

  componentDidMount() {
    TaskService.displayTasks({
      projectId: localStorage.getItem("simulation"),
    })
      .then((response) => {
        var tasks = [];
        var colorCount = 0;
        response.data.forEach((task, i) => {
          var tempTaskVar = { id: 0, start: new Date(), end: new Date() };
          tempTaskVar.id = task.UID;
          tempTaskVar.name = task.Name;
          tempTaskVar.start = new Date(task.StartDate);
          tempTaskVar.end = new Date(task.EndDate);
          tempTaskVar.color = task.TaskBarColor;
          tasks.push(tempTaskVar);
        });

        var count = 0;
        response.data.forEach((task, i) => {
          if (task.Predecessors !== null) {
            var predecessorArr = task.Predecessors.split(", ");
            predecessorArr.forEach((predecessor, i) => {
              var tempObj = { id: 0, start: 0, end: 0 };
              count++;
              tempObj.id = count;
              tempObj.start = predecessor;
              tempObj.end = task.UID;
              this.links.push(tempObj);
            });
          }
        });

        this.setState({
          simTasks: tasks,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    UserService.getSimGanttName({ simID: localStorage.getItem("simID") })
      .then((response) => {
        const name = response.data[0]["SimGantt"];
        this.setState({
          ganttName: name,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="inlineFlex">
        <SideBar></SideBar>
        {isLoading ? (
          <div className="loader"></div>
        ) : (
          <div className="resourceTable overflowAutoScroll">
            <div className="width100">
              <h5>Gantt Chart</h5>
            </div>

            <div className="overflowAutoScroll time-line-container">
              <TimeLine
                data={this.state.simTasks}
                mode="month"
                config={this.config}
                links={this.links}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

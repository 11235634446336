import React, { Component } from "react";
import AdminDataService from "../services/admin.service";
import UserService from "../services/user.service";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Modal from "react-bootstrap/Modal";

export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);
    this.processPeriod = this.processPeriod.bind(this);
    this.getSimulationsFunction = this.getSimulationsFunction.bind(this);
    this.processButtonFunc = this.processButtonFunc.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.resetSimulation = this.resetSimulation.bind(this);
    this.state = {
      isModalOpen: false,
      isModalOpenReset: false,
      isAdmin: false,
      selectedSim: 0,
      simulations: [],
      simulationSubmissionInfo: [],
      unsubmittedList: [],
      isLoading: false,
      maxperiod: [],
      columns: [
        { dataField: "ID", text: "ID", sort: true },
        {
          dataField: "SimDescription",
          text: "Sim Description",
          filter: textFilter(),
        },
        {
          dataField: "SimGroup",
          text: "Sim Group",
          filter: textFilter(),
        },
        {
          dataField: "SimTitle",
          text: "Sim Title",
          filter: textFilter(),
        },
        {
          dataField: "SimType",
          text: "Sim Type",
          filter: textFilter(),
        },
        {
          dataField: "CurrentPeriod",
          text: "Current Period",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "ProcessPeriodFlag",
          text: "Process Period Flag",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "SimStatus",
          text: "Sim Status",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "ProcessPeriod",
          text: "Process Period",
          formatter: this.linkProcessPeriod,
        },
        {
          dataField: "ResetSim",
          text: "Reset Simulation",
          formatter: this.linkResetSimulation,
        },
      ],
      submissionColumns: [
        { dataField: "SimID", text: "Simulation ID", sort: true },
        {
          dataField: "SimGroup",
          text: "Sim Group",
        },
        {
          dataField: "SimType",
          text: "Sim Type",
        },
        { dataField: "Submitted", text: "# Submitted" },
        {
          dataField: "Not_Submitted",
          text: "# Not Submitted",
        },
        {
          dataField: "Total",
          text: "Total",
        },
        {
          dataField: "Detail",
          text: "Detail",
          formatter: this.linkDetail,
        },
      ],
    };
  }

  linkProcessPeriod = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        onClick={() => {
          this.setState({
            isModalOpen: true,
            selectedSim: row.ID,
            currentPeriod: row.CurrentPeriod,
            simStatus: row.SimStatus,
          });
        }}
      >
        {row.SimStatus === "Completed" ? "Reset" : "Process"}
      </button>
    );
  };

  linkResetSimulation = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        onClick={() => {
          this.setState({
            isModalOpenReset: true,
            selectedSim: row.ID,
          });
        }}
      >
        Reset
      </button>
    );
  };

  linkDetail = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button
        onClick={() => {
          AdminDataService.getMaxPeriod({ sim_id: row.SimID }).then(
            (response) => {
              localStorage.setItem(
                "currentPeriod",
                response.data[0]["max_period"]
              );
            }
          );
          localStorage.setItem("total", row.Total);
          localStorage.setItem("simID", row.SimID);
          localStorage.setItem("simGroup", row.SimGroup);
          localStorage.setItem("simType", row.SimType);
          if (row.SimType === "Async") {
            this.props.history.push("/async_detail");
          } else {
            this.props.history.push("/detail");
          }
        }}
      >
        Show Detail
      </button>
    );
  };

  closeModal() {
    this.setState({ isModalOpen: false, isModalOpenReset: false });
  }

  processPeriodZero() {
    this.setState({
      isLoading: true,
      isModalOpen: false,
    });
    AdminDataService.processPeriodZero({ sim_id: this.state.selectedSim })
      .then((response) => {
        this.getSimulationsFunction();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  processPeriod() {
    this.setState({
      isLoading: true,
      isModalOpen: false,
    });
    AdminDataService.processPeriod({ sim_id: this.state.selectedSim })
      .then((response) => {
        this.getSimulationsFunction();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  resetSimulation() {
    this.setState({
      isLoading: true,
      isModalOpenReset: false,
    });
    AdminDataService.resetSimulation({ sim_id: this.state.selectedSim })
      .then((response) => {
        this.getSimulationsFunction();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  processButtonFunc() {
    if (this.state.simStatus === "Completed") {
      this.resetSimulation();
    } else if (this.state.currentPeriod == 0) {
      this.processPeriodZero();
    } else {
      this.processPeriod();
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    UserService.getAdminBoard().then(
      (response) => {
        this.setState({
          isAdmin: true,
          isLoading: false,
          user: localStorage.getItem("user"),
        });
        this.getSimulationsFunction();
      },
      (error) => {
        console.log(error.response);
      }
    );
  }

  getSimulationsFunction() {
    this.setState({
      isLoading: true,
    });
    AdminDataService.getSimulations({
      admin_id: JSON.parse(this.state.user).id,
    })
      .then((response) => {
        this.setState({
          simulations: response.data,
          isModalOpen: false,
          isModalOpenReset: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    AdminDataService.getSubmissionCount({
      admin_id: JSON.parse(this.state.user).id,
    })
      .then((response) => {
        this.setState({
          simulationSubmissionInfo: response.data,
          isModalOpen: false,
          isModalOpenReset: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="p20">
        <Modal show={this.state.isModalOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm period processing</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Are you sure you want to{" "}
              {this.state.simStatus === "Completed" ? "reset" : "process"} the
              period for simulation {this.state.selectedSim}?
            </p>
          </Modal.Body>

          <Modal.Footer>
            <button variant="secondary" onClick={this.closeModal}>
              No
            </button>
            <button variant="primary" onClick={this.processButtonFunc}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.isModalOpenReset} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm to reset the simulation</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Are you sure you want to reset the simulation{" "}
              {this.state.selectedSim}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button variant="secondary" onClick={this.closeModal}>
              No
            </button>
            <button variant="primary" onClick={this.resetSimulation}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>
        <div className={this.state.isLoading ? "loader" : ""}></div>
        {this.state.isAdmin ? (
          <div>
            <h3> Admin </h3>
            <h5> Process Period </h5>
            <div className="overflowAutoScroll">
              <BootstrapTable
                striped
                bordered
                hover
                keyField="ID"
                data={this.state.simulations}
                columns={this.state.columns}
                filter={filterFactory()}
              ></BootstrapTable>
            </div>
            <h5>Submission Count </h5>
            <div className="overflowAutoScroll">
              <BootstrapTable
                striped
                bordered
                hover
                keyField="ID"
                data={this.state.simulationSubmissionInfo}
                columns={this.state.submissionColumns}
                filter={filterFactory()}
              ></BootstrapTable>
            </div>
          </div>
        ) : (
          <h3> You need to be an admin to view this page. </h3>
        )}
      </div>
    );
  }
}

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL + "/pmSim/";

class UserService {
  getStudentBoard() {
    return axios.get(API_URL + "student", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }

  getUserProjects(id) {
    return axios.get(API_URL + "getUserProjects/" + id, {
      headers: authHeader(),
    });
  }

  getSimRanking(data) {
    return axios.post(API_URL + "getSimRanking/", data, {
      headers: authHeader(),
    });
  }

  submitPeriod(data) {
    return axios.post(API_URL + "submitPeriod/", data, {
      headers: authHeader(),
    });
  }

  unsubmitPeriod(data) {
    return axios.post(API_URL + "unsubmitPeriod/", data, {
      headers: authHeader(),
    });
  }

  fetchProjectInfo(data) {
    return axios.post(API_URL + "fetchProjectInfo/", data, {
      headers: authHeader(),
    });
  }

  metricProjectSummary(data) {
    return axios.post(API_URL + "metricProjectSummary/", data, {
      headers: authHeader(),
    });
  }

  metricResourceDetail(data) {
    return axios.post(API_URL + "metricResourceDetail/", data, {
      headers: authHeader(),
    });
  }

  metricCostDetail(data) {
    return axios.post(API_URL + "metricCostDetail/", data, {
      headers: authHeader(),
    });
  }

  metricWorkDetail(data) {
    return axios.post(API_URL + "metricWorkDetail/", data, {
      headers: authHeader(),
    });
  }

  getBackgroundTxtKey(data) {
    return axios.post(API_URL + "getBackgroundTxtKey/", data, {
      headers: authHeader(),
    });
  }

  getSimGuideName(data) {
    return axios.post(API_URL + "getSimGuideName/", data, {
      headers: authHeader(),
    });
  }

  getSimGanttName(data) {
    return axios.post(API_URL + "getSimGanttName/", data, {
      headers: authHeader(),
    });
  }

  processPeriodAsync(data) {
    return axios.post(API_URL + "processPeriodAsync/", data, {
      headers: authHeader(),
    });
  }

  resetSim(data) {
    return axios.post(API_URL + "resetSim/", data, {
      headers: authHeader(),
    });
  }

  processPeriodZeroAsync(data) {
    return axios.post(API_URL + "processPeriodZeroAsync/", data, {
      headers: authHeader(),
    });
    
  }

}

export default new UserService();

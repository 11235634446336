import React, { Component } from "react";
import SideBar from "./side-bar.js";
import ResourceDataService from "../services/resource.service";
import TaskService from "../services/task.service";
import UserService from "../services/user.service";
import Modal from "react-bootstrap/Modal";
import backgroundTxt from "../txtsrc/background.json";
import ActionService from "../services/action.service";

export default class SimBackgroundAsync extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.processPeriod = this.processPeriod.bind(this);
    this.processPeriodZero = this.processPeriodZero.bind(this);
    this.buttonFunc = this.buttonFunc.bind(this);
    this.pageLoadCallsFunction = this.pageLoadCallsFunction.bind(this);
    this.refreshFunc = this.refreshFunc.bind(this);
    this.state = {
      isModalOpen: false,
      isResetModalOpen: false,
      simulationId: localStorage.getItem("simulation"),
      simulationName: localStorage.getItem("simulationName"),
      overview: "",
      onboardingResources: [],
      onboardingResourcesLoadingFlag: true,
      hiredResources: [],
      hiredResourcesLoadingFlag: true,
      taskAssignment: [],
      taskAssignmentLoadingFlag: true,
      projID: localStorage.getItem("simulation"),
      period: localStorage.getItem("currentPeriod"),
      userRank: 0,
      userRankLoadedFlag: false,
      isLoading: false,
      decisionsSubmittedFlag: localStorage.getItem("decisionsSubmitted"),
      prjStatus: localStorage.getItem("prjStatus"),
      actionAssignment: [], // Initialize actionAssignment as an empty array
      actionAssignmentLoadingFlag: true,
      teamID: localStorage.getItem("teamID"),
    };
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  submitModal() {
    this.setState({ isModalOpen: true });
  }

  closeResetModal() {
    this.setState({ isResetModalOpen: false });
  }

  submitResetModal() {
    this.setState({ isResetModalOpen: true });
  }

  componentDidMount() {
    this.pageLoadCallsFunction();
    this.getActionAssignmentData();
  }

  pageLoadCallsFunction() {


    ActionService.GetActionAssignment({
      period: parseInt(localStorage.getItem("currentPeriod")),
      projectId: parseInt(localStorage.getItem("simulation")),
    })
      .then((response) => {
        this.setState({
          actionAssignment: response.data,
          actionAssignmentLoadingFlag: false,
        });
        console.log("Action Assignment Data:", response.data);
      })
      .catch((e) => {
        console.log("Error fetching action assignment data:", e);
      });

      
    ResourceDataService.retrieveResources({
      status: "Onboarding",
      projectId: localStorage.getItem("simulation"),
    })
      .then((response) => {
        this.setState({
          onboardingResources: response.data,
          onboardingResourcesLoadingFlag: false,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    ResourceDataService.retrieveResources({
      status: "Hired",
      projectId: localStorage.getItem("simulation"),
    })
      .then((response) => {
        this.setState({
          hiredResources: response.data,
          hiredResourcesLoadingFlag: false,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    TaskService.getTaskAssignmentWithResourceName({
      projectId: localStorage.getItem("simulation"),
      period: localStorage.getItem("currentPeriod"),
    })
      .then((response) => {
        this.setState({
          taskAssignment: response.data,
          taskAssignmentLoadingFlag: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    var periodForRank = this.state.period - 1;
    if (localStorage.getItem("prjStatus") === "Completed") {
      periodForRank = this.state.period;
    }
    UserService.getSimRanking({
      simID: localStorage.getItem("simID"),
      period: periodForRank,
    })
      .then((response) => {
        if (response.data.length === 0) {
          this.setState({
            userRank: "-",
            userRankLoadedFlag: true,
          });
        } else {
          var projId = localStorage.getItem("simulation");
          var userRankData;
          for (var i = 0; i <= response.data.length; i++) {
            if (response.data[i].ProjectID == projId) {
              userRankData = response.data[i].OverallRanking;
              break;
            }
          }
          if (response.data.length > 0) {
            this.setState({
              userRank: userRankData,
              userRankLoadedFlag: true,
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
    UserService.getBackgroundTxtKey({ simID: localStorage.getItem("simID") })
      .then((response) => {
        const key = response.data[0]["SimTxtKey"];
        this.setState({
          overview: backgroundTxt[key],
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getActionAssignmentData() {
    ActionService.GetActionAssignment({
      period: parseInt(localStorage.getItem("currentPeriod")),
      projectId: parseInt(localStorage.getItem("simulation")),
    })
      .then((response) => {
        this.setState({
          actionAssignment: response.data,
          actionAssignmentLoadingFlag: false,
        });
        console.log("Action Assignment Data:", response.data);
      })
      .catch((e) => {
        console.log("Error fetching action assignment data:", e);
      });
  }

  processPeriod() {
    this.setState({
      isLoading: true,
      isModalOpen: false,
    });
    UserService.processPeriodAsync({
      prj_id: localStorage.getItem("simulation"),
    })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.refreshFunc();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  processPeriodZero() {
    this.setState({ isLoading: true });
    UserService.processPeriodZeroAsync({
      prj_id: localStorage.getItem("simulation"),
    })
      .then((response) => {
        this.setState({
          isModalOpen: false,
          isLoading: false,
        });
        this.refreshFunc();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  resetSim() {
    this.setState({ isLoading: true });
    UserService.resetSim({ prj_id: localStorage.getItem("simulation") })
      .then((response) => {
        this.setState({
          isModalOpen: false,
          isLoading: false,
        });
        this.refreshFunc();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  buttonFunc() {
    if (this.state.prjStatus === "Completed") {
      this.resetSim();
    } else if (this.state.period == 0) {
      this.processPeriodZero();
    } else {
      this.processPeriod();
    }
  }

  refreshFunc() {
    this.setState({
      isLoading: true,
    });
    UserService.fetchProjectInfo({ ID: localStorage.getItem("simulation") })
      .then((response) => {
        localStorage.setItem("prjStatus", response.data[0].ProjectStatus);
        this.setState({
          isLoading: false,
          prjStatus: response.data[0].ProjectStatus,
        });
        if (
          response.data[0].PeriodStatus !=
          localStorage.getItem("decisionsSubmitted")
        ) {
          localStorage.setItem(
            "decisionsSubmitted",
            response.data[0].PeriodStatus
          );
          this.setState({
            decisionsSubmittedFlag: response.data[0].PeriodStatus.toString(),
          });
        }

        if (
          response.data[0].CurrentPeriod !=
          localStorage.getItem("currentPeriod")
        ) {
          localStorage.setItem("currentPeriod", response.data[0].CurrentPeriod);
          this.setState({
            period: response.data[0].CurrentPeriod,
          });
          this.pageLoadCallsFunction();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const actionResourceMap = {};
    const hasActionAssignmentData = this.state.actionAssignment.length > 0;

  
    // Iterate through the original data and group resource names by actionName
    this.state.actionAssignment.forEach((item) => {
      const { actionName, resourceName } = item;
      if (actionResourceMap[actionName]) {
        actionResourceMap[actionName].push(resourceName);
      } else {
        actionResourceMap[actionName] = [resourceName];
      }
    });
  
    // Extract the six unique action names from the keys of the actionResourceMap
    const uniqueActionNames = Object.keys(actionResourceMap).slice(0, 6);
  
    // Create the final array containing six action names with their resource names
    const actionAssignmentProcessed = uniqueActionNames.map((actionName) => ({
      actionName,
      resourceNames: actionResourceMap[actionName].join(', ')
    }));
    console.log(actionAssignmentProcessed);
  
    return (
      <div className="inlineFlex">
        <SideBar></SideBar>
        <div className="p20">
          <Modal show={this.state.isModalOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Comfirm here</Modal.Title>
            </Modal.Header>
  
            <Modal.Body>
              <p>
                {this.state.prjStatus === "Completed"
                  ? "Are you sure you want to reset the simulation?"
                  : "Are you sure you want to submit decisions for this period?"}
              </p>
            </Modal.Body>
  
            <Modal.Footer>
              <button variant="secondary" onClick={this.closeModal}>
                No
              </button>
              <button variant="primary" onClick={this.buttonFunc}>
                Yes
              </button>
            </Modal.Footer>
          </Modal>
  
          <h5>Welcome to Simulation: {this.state.simulationName}!</h5>
          <div className="row width100" style={{ display: "inline" }}>
            <div style={{ float: "left" }}>
              Click on the reset button to reset your simulation data.
            </div>
            <button
              className="btn_primary"
              style={{ float: "right", padding: "5px" }}
              onClick={this.refreshFunc}
            >
              Refresh
            </button>
          </div>
  
          <div className={this.state.isLoading ? "loader" : ""}></div>
  
          <div className="row p10 mt10" style={{ backgroundColor: "#f5f5f5" }}>
            <h6>Background:</h6>
            <p>{this.state.overview}</p>
          </div>
  
          <div className="p10 mt10" style={{ backgroundColor: "#f5f5f5" }}>
            <h6>Player's Information:</h6>
            <div className="row">
              <div className="col-md-3 text-center" style={{ padding: "0px" }}>
                <div style={{ backgroundColor: "white", margin: "5px" }}>
                  Team ID
                  <div style={{ fontSize: "50px", fontWeight: "bold" }}>
                    {this.state.teamID}
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center" style={{ padding: "0px" }}>
                <div style={{ backgroundColor: "white", margin: "5px" }}>
                  Current Period
                  <div style={{ fontSize: "50px", fontWeight: "bold" }}>
                    {this.state.period}
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center" style={{ padding: "0px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    margin: "5px",
                    height: "calc(100% - 10px)",
                  }}
                >
                  Current Period Status{" "}
                  {this.state.decisionsSubmittedFlag === "0"
                    ? ": Not Submitted"
                    : ": Submitted"}
                  <div>
                    <button
                      className="btn_primary"
                      style={{ padding: "5px", margin: "20px" }}
                      onClick={this.submitModal}
                    >
                      {this.state.prjStatus === "Completed"
                        ? "Reset"
                        : "Process"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center" style={{ padding: "0px" }}>
                <div style={{ backgroundColor: "white", margin: "5px" }}>
                  Overall Rank
                  {this.state.userRankLoadedFlag ? (
                    <div style={{ fontSize: "50px", fontWeight: "bold" }}>
                      {this.state.userRank}
                    </div>
                  ) : (
                    <div className="summaryLoader"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
  
          {this.state.prjStatus === "Completed" ? (
            <div
              className="row p10 mt10"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <h6>Simulation Status: &nbsp;</h6>
              <p>Completed</p>
            </div>
          ) : (
            <div>
              <div className="p10 mt10" style={{ backgroundColor: "#f5f5f5" }}>
                <h6> Team Management Decisions </h6>
                <div className="row">
                  <div className="col-md-6">
                    <strong> Hired Resources </strong>
                    {this.state.hiredResourcesLoadingFlag ? (
                      <div className="summaryLoader"></div>
                    ) : this.state.hiredResources.length === 0 ? (
                      <div>You do not have any hired resources.</div>
                    ) : (
                      <table className="width100 summaryTable">
                        <thead>
                          <tr>
                            <th>Resource Name</th>
                            <th>Resource Category</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.hiredResources.map((row, i) => (
                            <tr key={row.resourceID}>
                              <td>{row.resourceName}</td>
                              <td>{row.resourceCategory}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="col-md-6">
                    <strong> Onboarding Resources </strong>
                    {this.state.onboardingResourcesLoadingFlag ? (
                      <div className="summaryLoader"></div>
                    ) : this.state.onboardingResources.length <= 0 ? (
                      <div>You do not have any resources being onboarded.</div>
                    ) : (
                      <table className="width100 summaryTable">
                        <thead>
                          <tr>
                            <th>Resource Name</th>
                            <th>Resource Category</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.onboardingResources.map((row, i) => (
                            <tr key={row.resourceID}>
                              <td>{row.resourceName}</td>
                              <td>{row.resourceCategory}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
  
              <div
                className="row p10 mt10"
                style={{ backgroundColor: "#f5f5f5", display: "block" }}
              >
                <h6>Task Management Decisions</h6>
                {this.state.taskAssignmentLoadingFlag ? (
                  <div className="summaryLoader"></div>
                ) : this.state.taskAssignment.length <= 0 ? (
                  <div>
                    You do not have any task assignment for this period.
                  </div>
                ) : (
                  <table className="width100 summaryTable">
                    <thead>
                      <tr>
                        <th>Task Name</th>
                        <th>Resource Name</th>
                        <th>Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.taskAssignment.map((row, i) => (
                        <tr key={i}>
                          <td>{row.TaskListName}</td>
                          <td>{row.resourceName}</td>
                          <td>{row.Priority}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
  
          {/* Leadership Action Decisions */}
          <div className="p10 mt10" style={{ backgroundColor: "#f5f5f5" }}>
            <h6>Leadership Action Decisions</h6>
            {/* Display your leadership action decisions here */}
            {this.state.actionAssignmentLoadingFlag ? (
              <div className="summaryLoader"></div>
            ) : hasActionAssignmentData ? (
              <table className="width100 summaryTable">
                <thead>
                  <tr>
                    <th>Action Name</th>
                    <th>Resource Names</th>
                  </tr>
                </thead>
                <tbody>
                  {actionAssignmentProcessed.map((action, i) => (
                    <tr key={i}>
                      <td>{action.actionName}</td>
                      <td>{action.resourceNames}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>You do not have any action assignment for this period.</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  

  
}

// Get all keys in localStorage
const keys = Object.keys(localStorage);

// Loop through the keys to access the values
keys.forEach((key) => {
  const value = localStorage.getItem(key);
  console.log(`${key}: ${value}`);
});

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { Nav } from "react-bootstrap";

export default class SideBarAdmin extends React.Component {
  render() {
    return (
      <Nav
        justify
        defaultActiveKey="/not-submitted-list"
        className="flex-column sideNav"
      >
        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Dashboard
          </Nav.Link>
        </strong>
        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Leadership Action
          </Nav.Link>
        </strong>


        <span className="sideNavList">
          {" "}
          {localStorage.getItem("simType") == "Async" ? (
            <Nav.Link
              as={NavLink}
              to="/async_detail"
              activeClassName="selectedTab"
            >
              Status
            </Nav.Link>
          ) : (
            <Nav.Link as={NavLink} to="/detail" activeClassName="selectedTab">
              Status
            </Nav.Link>
          )}
        </span>
        <span className="sideNavList">
          {" "}
          <Nav.Link
            as={NavLink}
            to="/metrics-admin"
            activeClassName="selectedTab"
          >
            Metrics
          </Nav.Link>
        </span>
        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Results
          </Nav.Link>
        </strong>
        <span className="sideNavList">
          {" "}
          <Nav.Link
            as={NavLink}
            to="/ranking-admin"
            activeClassName="selectedTab"
          >
            Ranking
          </Nav.Link>
        </span>
      </Nav>
    );
  }
}

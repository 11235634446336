import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { Nav } from "react-bootstrap";

export default class SideBar extends React.Component {
  render() {
    return (
      <Nav justify defaultActiveKey="/home" className="flex-column sideNav">
        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Dashboard
          </Nav.Link>
        </strong>
        <span className="sideNavList">
          {localStorage.getItem("desc") == "Async" ? (
            <Nav.Link
              as={NavLink}
              to="/async_status"
              activeClassName="selectedTab"
            >
              Status
            </Nav.Link>
          ) : (
            <Nav.Link as={NavLink} to="/status" activeClassName="selectedTab">
              Status
            </Nav.Link>
          )}
        </span>
        <span className="sideNavList">
          {" "}
          <Nav.Link as={NavLink} to="/metrics" activeClassName="selectedTab">
            Metrics
          </Nav.Link>
        </span>

        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Leadership Action
          </Nav.Link>
        </strong>
        <span className="sideNavList">
          {" "}
          <Nav.Link as={NavLink} to="/iLeadershipActions" activeClassName="selectedTab">
            Actions
          </Nav.Link>
        </span>
        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Team Mgmt
          </Nav.Link>
        </strong>
        <span className="sideNavList">
          {" "}
          <Nav.Link as={NavLink} to="/resources" activeClassName="selectedTab">
            Available
          </Nav.Link>
        </span>
        <span className="sideNavList">
          {" "}
          <Nav.Link
            as={NavLink}
            to="/hiredResources"
            activeClassName="selectedTab"
          >
            Hired
          </Nav.Link>
        </span>
        <span className="sideNavList">
          {" "}
          <Nav.Link
            as={NavLink}
            to="/onboardingResources"
            activeClassName="selectedTab"
          >
            Onboarding
          </Nav.Link>
        </span>
        <span className="sideNavList">
          {" "}
          <Nav.Link
            as={NavLink}
            to="/teamResources"
            activeClassName="selectedTab"
          >
            On Team
          </Nav.Link>
        </span>
        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Task Mgmt
          </Nav.Link>
        </strong>
        <span className="sideNavList">
          {" "}
          <Nav.Link as={NavLink} to="/tasks" activeClassName="selectedTab">
            Task List
          </Nav.Link>
        </span>
        <span className="sideNavList">
          {" "}
          <Nav.Link as={NavLink} to="/ganttChart" activeClassName="selectedTab">
            Gantt Chart
          </Nav.Link>
        </span>
        <span className="sideNavList">
          {" "}
          <Nav.Link
            as={NavLink}
            to="/taskAssignment"
            activeClassName="selectedTab"
          >
            Assignment
          </Nav.Link>
        </span>
        <strong>
          <Nav.Link eventKey="disabled" disabled>
            Results
          </Nav.Link>
        </strong>
        <span className="sideNavList">
          {" "}
          <Nav.Link as={NavLink} to="/ranking" activeClassName="selectedTab">
            Ranking
          </Nav.Link>
        </span>
      </Nav>
    );
  }
}
